import { getTextWidth } from '../helpers/text';

// eslint-disable-next-line import/no-default-export
export default class Legends {
  markerSize = 25;

  marginRatio = 1.5;

  constructor(d3Elem, LegendConfig) {
    this.config = LegendConfig;
    this.element = d3Elem;

    if (LegendConfig.isHorizontal) {
      this.drawHorizontalLegend();
    } else {
      this.drawVerticalLegend();
    }
  }

  /**
   * Draws the entries of the legend within a single line
   * @private
   */
  drawHorizontalLegend() {
    let xOffset = 25;
    const cx = this;
    const textSize = 12;
    const textLetterSpacing = 0.5;
    const markerYOffset = -(textSize - 2) / 2;
    const { data, margin, colorSchema } = this.config;

    this.element.selectAll('g').remove();

    // We want a single line
    const legendLine = this.element.append('g').classed('legend-line', true);

    // And one entry per data item
    const entries = legendLine.selectAll('g.legend-entry').data(data);

    // Enter
    entries
      .enter()
      .append('g')
      .classed('legend-entry', true)
      .attr('transform', group => {
        const horizontalOffset = xOffset;
        const lineHeight = margin.top / 2;
        const verticalOffset = lineHeight;
        const labelWidth = getTextWidth(group, textSize);

        xOffset += cx.markerSize + 2 * cx.getLineElementMargin() + labelWidth;

        return `translate(${horizontalOffset},${verticalOffset})`;
      })
      .merge(entries)
      .append('rect')
      .classed('legend-rect', true)
      .attr('width', this.markerSize)
      .attr('height', this.markerSize)
      .attr('x', this.markerSize / 2 - 4)
      .attr('y', -(this.markerSize - textSize - markerYOffset))
      .attr('rx', 5)
      .attr('stroke', (_, i) => colorSchema[i % colorSchema.length])
      .attr('fill', (_, i) => colorSchema[i % colorSchema.length])
      .style('stroke-width', 2);

    this.element
      .selectAll('g.legend-entry')
      .append('text')
      .classed('legend-entry-name', true)
      .text(d => d)
      .attr('x', cx.getLineElementMargin())
      .style('font-size', `${textSize}px`)
      .style('letter-spacing', `${textLetterSpacing}px`);

    this.adjustLines();
  }

  /**
   * Draws the entries of the legend
   * @private
   */
  drawVerticalLegend() {
    const cx = this;
    const textSize = 12;
    const textLetterSpacing = 0.5;
    const markerYOffset = -(textSize - 2) / 2;
    const { data, colorSchema, chartHeight } = this.config;

    this.element.selectAll('g').remove();

    const entries = this.element.selectAll('g.legend-line').data(data);

    // Enter
    entries
      .enter()
      .append('g')
      .classed('legend-line', true)
      .append('g')
      .classed('legend-entry', true)
      .attr('data-item', d => d)
      .attr('transform', (_, i) => {
        const horizontalOffset = cx.markerSize + cx.getLineElementMargin();
        const lineHeight = chartHeight / (data.length + 1);
        const verticalOffset = (i + 1) * lineHeight;
        return `translate(${horizontalOffset},${verticalOffset})`;
      })
      .merge(entries)
      .append('rect')
      .classed('legend-circle', true)
      .attr('cx', this.markerSize / 2)
      .attr('cy', markerYOffset)
      .attr('r', this.markerSize / 2)
      .attr('stroke', (_, i) => colorSchema[i % colorSchema.length])
      .attr('fill', (_, i) => colorSchema[i % colorSchema.length])
      .style('stroke-width', 1);

    this.element
      .selectAll('g.legend-entry')
      .append('text')
      .classed('legend-entry-name', true)
      .text(d => d)
      .attr('x', this.getLineElementMargin())
      .style('font-size', `${textSize}px`)
      .style('letter-spacing', `${textLetterSpacing}px`);

    this.centerVerticalLegendOnSVG();
  }

  /**
   * Calculates the margin between elements of the legend
   * @return {Number} Margin to apply between elements
   * @private
   */
  getLineElementMargin() {
    return this.marginRatio * this.markerSize;
  }

  /**
   * Centers the legend on the chart given that is a stack of labels
   * @return {void}
   * @private
   */
  centerVerticalLegendOnSVG() {
    const { dimensions } = this.config;
    const legendGroupSize = this.element.node().getBoundingClientRect().width;
    const emptySpace = dimensions.width - legendGroupSize;
    const newXPosition = emptySpace / 2 - legendGroupSize / 2;

    if (emptySpace > 0) {
      this.element
        .select('g.legend-container-group')
        .attr('transform', `translate(${newXPosition},0)`);
    }
  }

  /**
   * Depending on the size of the horizontal legend, we are going to add a new
   * line with the last entry of the legend
   * @return {void}
   * @private
   */
  adjustLines() {
    const { chartWidth, align, isCombo, margin, chartHeight } = this.config;
    const lineWidth =
      this.element.select('.legend-line').node().getBoundingClientRect().width + this.markerSize;
    const lineWidthSpace = chartWidth - lineWidth;

    if (lineWidthSpace <= 0) {
      this.splitInLines();
    }

    if (align === 'center') this.centerInlineLegendOnSVG();

    this.element.attr('transform', `translate(0, ${isCombo ? chartHeight + 30 : 0})`);
  }

  /**
   * Centers the legend on the chart given that is a single line of labels
   * @return {void}
   * @private
   */
  centerInlineLegendOnSVG() {
    const { dimensions, isCombo, chartHeight } = this.config;
    const legendGroupSize =
      this.element.node().getBoundingClientRect().width + this.getLineElementMargin();
    const emptySpace = dimensions.width - legendGroupSize;
    const newXPosition = emptySpace / 2;
    if (emptySpace > 0) {
      this.element.attr(
        'transform',
        `translate(${newXPosition}, ${isCombo ? chartHeight + 50 : 0})`,
      );
    }
  }

  /**
   * Simple method to move the last item of an overflowing legend into the next line
   * @return {void}
   * @private
   */
  splitInLines() {
    const { chartHeight } = this.config;
    const legendEntries = this.element.selectAll('.legend-entry');
    const numberOfEntries = legendEntries.size();
    const lineHeight = (chartHeight / 2) * 1.7;
    const newLine = this.element
      .select('.legend-group')
      .append('g')
      .classed('legend-line', true)
      .attr('transform', `translate(0, ${lineHeight})`);
    const lastEntry = legendEntries.filter(`:nth-child(${numberOfEntries})`);

    lastEntry.attr('transform', `translate(${this.markerSize},0)`);
    newLine.append(() => lastEntry.node());
  }
}

import SimpleBarChart from './Simple-bar';
import GroupedBarChart from './Grouped-bars';
import LineChart from './Line';

/**
 * Graph selector
 */
export class Graph {
  // eslint-disable-next-line consistent-return
  static draw(graphArea, graphConfig) {
    const chartType = graphConfig.type;
    // eslint-disable-next-line default-case
    switch (chartType) {
      case 'BAR_CHART':
        return new SimpleBarChart(graphArea, graphConfig);
      case 'BAR_CHART_MS':
        return new GroupedBarChart(graphArea, graphConfig);
      case 'LINE_CHART':
        return new LineChart(graphArea, graphConfig);
    }
  }
}

/* eslint-disable radix */
import { isNaNV2, _unique } from './utils';

export class DataPreparation {
  categories = [];

  series = [];

  data = [];

  minMax = [Infinity, -Infinity];

  layerConfig;

  constructor(layerConfig) {
    this.layerConfig = layerConfig;
    const { type } = layerConfig;

    if (type === 'BAR_CHART_MS') {
      this.processMultiData();
    } else {
      this.processSingleData();
    }

    this.fixMinMax();
  }

  saveCategories(d, x) {
    this.categories.push(d[x]);
  }

  processMultiData() {
    const { data, fieldX, fieldY, fieldSerie } = this.layerConfig;
    const x = fieldX || 'x';
    const y = fieldY || 'y';
    const serie = fieldSerie || 'serie';
    const { colorSchema } = this.layerConfig;

    this.series = _unique(data.map(d => d[serie]));
    this.categories = _unique(data.map(d => d[x]));

    const nameGroupCount = data.reduce((obj, item) => {
      const name = item[x];
      const group = item[serie];
      const value = item[y];

      if (!(group in obj)) {
        obj[group] = {
          [name]: value,
        };
      } else {
        // I.e. if the year doesn't exist, default to zero
        obj[group][name] = value;
      }
      return obj;
    }, {});

    // Now transform it into the desired format
    const transformedData = Object.entries(nameGroupCount).map(([name, groupCount]) => {
      const values = Object.entries(groupCount).map(([group, count]) => ({
        key: group,
        value: count,
      }));
      return {
        key: name,
        values,
      };
    });

    this.categories.forEach(key => {
      const layer = { key, values: [] };
      transformedData.forEach((item, i) => {
        const color = colorSchema[i % colorSchema.length];
        item.values.forEach(v => {
          this.calcMinMax(v.value);
          if (key === v.key) {
            layer.values.push({
              serie: item.key,
              x: key,
              y: v.value,
              y0: v.value >= 0 ? 0 : v.value,
              y1: v.value >= 0 ? v.value : 0,
              color,
            });
          }
        });
      });
      this.data.push(layer);
    });
  }

  processSingleData() {
    const { data, fieldX, fieldY } = this.layerConfig;
    const x = fieldX || 'x';
    const y = fieldY || 'y';

    data.forEach((d, i) => {
      // set categories for X Axis domain
      this.saveCategories(d, x);
      // calc max and min for Y Axis
      this.analysis(d[x], d[y], i, { ...d });
    });
  }

  analysis(category, val, index, originalData) {
    const { colorSchema, isCombo, layerIndex } = this.layerConfig;
    const color = isCombo ? colorSchema[layerIndex] : colorSchema[index % colorSchema.length];

    this.calcMinMax(val);

    this.data.push({
      x: category,
      y: val,
      y0: val >= 0 ? 0 : val,
      y1: val >= 0 ? val : 0,
      color,
      originalData,
    });
  }

  calcMinMax(value) {
    if (isNaNV2(value)) return;
    if (value < this.minMax[0]) this.minMax[0] = parseInt(value);
    if (value > this.minMax[1]) this.minMax[1] = parseInt(value);
  }

  fixMinMax() {
    if (this.minMax[0] > 0) this.minMax[0] = 0;
    if (this.minMax[1] < 0) this.minMax[1] = 0;
  }
}

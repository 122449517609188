/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-default-export */
import * as d3 from 'd3';

import { roundedRect } from '../../utils';
import Tooltip from '../../helpers/tooltips';
import { bandCalcs } from './helpers/utils';
import * as LoadingMarkup from './helpers/load';
import { uniqueId } from './helpers/number';

/**
 * @typedef GroupedBarChartData
 * @type {Object[]}
 * @property {Number} value        Value of the group (required)
 * @property {String} name         Name of the group (required)
 *
 * @example
 * [
 *     {
 *         value: 1,
 *         name: 'glittering'
 *     },
 *     {
 *         value: 1,
 *         name: 'luminous'
 *     }
 * ]
 */

/**
 * @typedef LocaleObject
 * @type {Object}
 * @property {String} decimal       the decimal point(e.g., ".")
 * @property {String} thousands     the group separator(e.g., ",")
 * @property {Number[]} grouping    the array of group sizes(e.g., [3]), cycled as needed
 * @property {String[]} currency    the currency prefix and suffix(e.g., ["$", ""])
 * @property {String[]} numerals    optional; an array of ten strings to replace the numerals 0 - 9.
 * @property {String} percent       optional; the percent sign(defaults to "%")
 * @property {String} minus         optional; the minus sign(defaults to hyphen - minus, "-")
 * @property {String} nan           optional; the not - a - number value(defaults "NaN")
 *
 * See some standard locale object values [here]{@link https://cdn.jsdelivr.net/npm/d3-format/locale/}.
 * @example
 * {
 *     "decimal": ",",
 *     "thousands": ".",
 *     "grouping": [3],
 *     "currency": ["", "\u00a0€"]
 * }
 */

/**
 * Bar Chart reusable API class that renders a
 * simple and configurable bar chart.
 *
 * @module GroupedBarChart
 * @tutorial GroupedBarChart
 * @requires d3-array, d3-axis, d3-dispatch, d3-scale, d3-selection
 *
 * @example
 * var barChart = bar();
 *
 * barChart
 *     .height(500)
 *     .width(800);
 *
 * d3.select('.css-selector')
 *     .datum(dataset)
 *     .call(barChart);
 *
 */
export default class GroupedBarChart {
  graphArea;

  _dimensions;

  _loadingState = LoadingMarkup.bar;

  GraphConfig;

  _data;

  _uniqueId = uniqueId('bar-chart');

  _xAxis;

  _yAxis;

  _svg;

  // extractors
  getRadiusByValue = (value, height) => {
    if (value === 0) return 0;
    return height < 12 ? height / 2 : 10;
  };

  constructor(element, config) {
    this.GraphConfig = config;
    this.graphArea = element;
    this.init();
  }

  /**
   * Draws the bars along the x axis
   * @param  {d3} bars Selection of bars
   * @return {void}
   */
  drawHorizontalBars(bars, graphCals) {
    // Enter + Update
    const cx = this;
    const { xScale, yScale } = this.GraphConfig;
    const bar = ({ x, y }) =>
      roundedRect(
        0,
        yScale(x),
        xScale(y),
        graphCals.bandwidth,
        cx.getRadiusByValue(y),
        false,
        true,
        false,
        true,
      );
    bars
      .enter()
      .append('path')
      .classed('bar', true)
      .attr('fill', d => d.color)
      .attr('transform', `translate(0, ${graphCals.translate})`)
      .attr('d', bar);
    // .on('mouseover', function (d, index, barList) {
    //     this.handleMouseOver(this, d, barList, this._chartWidth, this._chartHeight);
    // })
    // .on('mousemove', function (d) {
    //     this.handleMouseMove(this, d, this._chartWidth, this._chartHeight);
    // })
    // .on('mouseout', function (d, index, barList) {
    //     this.handleMouseOut(this, d, barList, this._chartWidth, this._chartHeight);
    // })
    // .on('click', function (d) {
    //     this.handleClick(this, d, this._chartWidth, this._chartHeight);
    // })
  }

  /**
   * Draws the bars along the y axis
   * @param  {d3} layersSelection Selection of layers
   * @return {void}
   */
  drawVerticalBars(layersSelection) {
    // Enter + Update
    const cx = this;
    const { barWidth, xScale, xScale2, yScale, chartHeight, data, tooltipTemplate, type } =
      this.GraphConfig;
    const layerJoin = layersSelection.data(data);

    const bar = ({ x, y, serie }) =>
      roundedRect(
        xScale2(serie),
        yScale(y),
        barWidth,
        chartHeight - yScale(y),
        cx.getRadiusByValue(y, chartHeight - yScale(y)),
        true,
        true,
      );

    const layerElements = layerJoin
      .enter()
      .append('g')
      .attr('transform', ({ key }) => `translate(${xScale(key)},0)`)
      .classed('layer', true);

    const barJoin = layerElements.selectAll('.bar').data(({ values }) => values);

    const bars = barJoin
      .enter()
      .append('path')
      .classed('bar', true)
      .attr('stroke', d => d.color)
      .attr('fill', d => d.color)
      .attr('d', bar)
      .each(function (d, index) {
        this.__datapoint_info__ = {
          element: this,
          value: d,
          valueIndex: index,
          type: 'DATAPOIND',
        };
      });

    bars
      .on('mouseover', function (d, index, barList) {
        const tooltipContent = tooltipTemplate(this.__datapoint_info__, type);
        Tooltip.setContent(tooltipContent).show(d);
      })
      .on('mouseout', (event, d) => {
        Tooltip.hide();
      })
      .on('mousemove', function (d, index, barList) {
        const tooltipContent = tooltipTemplate(this.__datapoint_info__, type);
        Tooltip.setContent(tooltipContent).show(d);
      });
  }

  /**
   * Draws the bar elements within the chart group
   * @private
   */
  drawBars() {
    const { barWidth, yScale, xScale, isHorizontal } = this.GraphConfig;
    const graphCals = bandCalcs(barWidth, yScale, xScale, isHorizontal);
    const bars = this.graphArea.selectAll('.bar').data(this._data);

    if (isHorizontal) {
      this.drawHorizontalBars(bars, graphCals);
    } else {
      this.drawVerticalBars(bars, graphCals);
    }

    // Exit
    bars.exit().remove();
  }

  /**
   * Custom OnMouseOver event handler
   * @return {void}
   * @private
   */
  handleMouseOver(e, d, barList, chartWidth, chartHeight) {
    this.GraphConfig.tooltipTemplate =
      this.GraphConfig.tooltipTemplate ||
      // eslint-disable-next-line func-names
      function (data) {
        return `<p>${data.name} </br> ${data.value}</p>`;
      };

    const tooltipContent = this.GraphConfig.tooltipTemplate(e.__datapoint_info___.data);
    Tooltip.setContent(tooltipContent).show(d);

    d3.select(e).attr('opacity', '0.9');

    barList &&
      barList.forEach(barRect => {
        if (barRect === e) {
          return;
        }
        d3.select(barRect).attr('opacity', '0.9');
      });
  }

  /**
   * Custom OnMouseMove event handler
   * @return {void}
   * @private
   */
  handleMouseMove(e, d, chartWidth, chartHeight) {
    // const tooltipContent = tooltipTemplate(e.__datapoint_info___.data);
    // Tooltip.setContent(tooltipContent).show(d);
  }

  /**
   * Custom OnMouseOver event handler
   * @return {void}
   * @private
   */
  handleMouseOut(e, d) {
    // d3.select(e).attr('fill', ({ name }) => computeColor(name));
    d3.select(e).attr('opacity', '1');
    Tooltip.hide();
  }

  /**
   * Custom onClick event handler
   * @return {void}
   * @private
   */
  handleClick(e, d, chartWidth, chartHeight) {}

  init() {
    this._chartWidth = this.GraphConfig.chartWidth;
    this._chartHeight = this.GraphConfig.chartHeight;
    this._data = this.GraphConfig.data;
    this.drawBars();
  }
}

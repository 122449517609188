/* eslint-disable import/no-default-export */
import React from 'react';
import cx from 'classnames';
import { Charts } from '../../graphs';
import BaseChart from './base-chart';
import styles from './styles.module.scss';

export default class Chart extends BaseChart {
  componentDidMount() {
    this.chart = new Charts(this.chartRef, {
      data: this.props.data,
      options: this.props.options,
    });
  }

  render() {
    return React.createElement('div', {
      ref: chartRef => (this.chartRef = chartRef),
      className: cx(styles['chart-holder']),
      style: {
        display: 'block',
        width: '100%',
        height: '100%',
        minHeight: '200px',
      },
    });
  }
}


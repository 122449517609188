export const Colors = {
  primary: '#25c16a',
  secondary: '#1c9050',
  alert: '#ef5d52',
  orange: '#f9b863',
  white: '#ffffff',
  black: '#2d2d2d',
  gray: '#b7b7b7',
  blue: '#065F71',
  purple: '#5E26A9',
  primaTransparent: 'rgba(37, 193, 106, 0.2)',
  grayGridlines: 'rgba(45, 45, 45, 0.2)'
};

/**
 * Validate if the recieved number is NaN.
 * This function gets any variable but it will return false.
 * Validate if variable is null, undefined, or an empty string, also, function uses isNaN native function.
 * @return True if variable is a NaN or false otherwise
 */
export const isNaNV2 = variable => {
  return variable == null || variable === '' || isNaN(variable);
};
export const isEmptyValue = variable => {
  return variable === undefined || variable === null || variable === '';
};
/**
 * Remove duplicated items from a given array by property name
 * // 1. Store the keys of the unique objects
 * // 2. Eliminate the dead keys & store unique objects
 * @param arr The array
 * @param prop Property name to search duplicated values
 * @return Array without duplicated items.
 */
export const getUniqueArray = (arr, prop) => {
  return arr
    .map(e => e[prop])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => arr[e])
    .map(e => arr[e]);
};
/**
 * get unique vaues from an Array of primitive JS types
 * @param arr Array of primitive types
 */
// eslint-disable-next-line no-underscore-dangle
export const _unique = arr => Array.from(new Set(arr));

export const bandCalcs = (bandwidth = 50, yScale, xScale, isHorizontal = false) => {
  let bandSize = 0;
  let translate = 0;
  // ordinal scale
  bandSize = isHorizontal ? yScale.bandwidth() : xScale.bandwidth();
  translate = (bandSize - bandwidth) / 2;
  return { bandwidth, bandSize, translate };
};

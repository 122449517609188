/* eslint-disable no-underscore-dangle */
import * as d3 from 'd3';
import { truncateString, formatNumber } from '../../../utils';
import { Colors } from '../../../constants';

/**
 * Axis reusable API class that renders a
 * simple and configurable axis helper.
 *
 * @module Bar
 * @tutorial bar
 * @requires d3-array, d3-axis, d3-dispatch, d3-scale, d3-selection
 *
 * @example
 * const axis = new Axis('botton');
 *
 *
 */

// eslint-disable-next-line import/no-default-export
export default class Axis {
  singleTickWidth = 20;

  horizontalTickSpacing = 50;

  minEntryNumForDayFormat = 5;

  config;

  element;

  axis;

  scale;

  constructor(d3Elem, AxisConfig) {
    this.config = AxisConfig;
    this.element = d3Elem;
    this.setRange();
    this.setDomain();
    this.setScale();
    this.buildAxis();
    this.drawAxis();
  }

  /**
   * Creates the d3  axis, setting orientations
   * @private
   */
  buildAxis() {
    this.axis = this.getAxisFn();
    if (this.config.isVertical) {
      this.axis.ticks(this.config.ticks).tickSize(0).tickPadding([16]);
    } else {
      this.axis.tickSize(0).tickPadding([8]);
    }
  }

  /**
   * Calculates the maximum number of ticks for the x axis
   * @param  {Number} width               Chart width
   * @param  {Number} dataPointNumber     Number of entries on the data
   * @return {Number}                     Number of ticks to render
   */
  getMaxNumOfHorizontalTicks = (width, dataPointNumber) => {
    const ticksForWidth = Math.ceil(width / (this.singleTickWidth + this.horizontalTickSpacing));

    return dataPointNumber < this.minEntryNumForDayFormat
      ? d3.timeDay
      : Math.min(dataPointNumber, ticksForWidth);
  };

  /**
   * Calculates the maximum number of ticks for the x axis
   * with respect to number ranges
   * @param  {Number} width               Chart width
   * @param  {Number} dataPointNumber     Number of entries on the data
   * @return {Number}                     Number of ticks to render
   */
  getMaxNumOfHorizontalTicksForNumberRanges = (width, dataPointNumber) => {
    const ticksForWidth = Math.ceil(width / (this.singleTickWidth + this.horizontalTickSpacing));
    return Math.min(dataPointNumber, ticksForWidth);
  };

  getAxisFn() {
    return d3[`axis${this.config.position}`](this.config.scale);
  }

  /**
   * Draws the x and y axis on the svg object within their
   * respective groups
   * @private
   */
  drawAxis() {
    const { isHorizontal, numberFormat, isVertical, position, tickSuffix } = this.config;
    const cx = this;
    const axis = this.element.call(this.axis);
    const domainColor = position === 'Right' ? 'transparent' : Colors.grayGridlines;
    let format;
    let setText;

    if (isVertical) {
      format = d => {
        if (!d) return null;
        return isHorizontal
          ? `${d} ${tickSuffix || ''}`
          : `${formatNumber(d, numberFormat)} ${tickSuffix || ''}`;
      };

      setText = d => {
        if (!d) return null;
        if (isHorizontal) {
          return parseFloat(d) ? truncateString(format(d), 10) : truncateString(format(d), 8);
        }
        return format(d);
      };
    } else {
      format = d => {
        if (!d) return null;
        return isHorizontal ? formatNumber(d, numberFormat) : d;
      };
      setText = d => {
        if (!d) return null;
        if (isHorizontal) return parseFloat(d) ? format(d) : truncateString(format(d), 10);
        return truncateString(format(d), 8);
      };
    }
    axis
      .selectAll(`.tick text`)
      .attr('class', 'sfviz-axis__tick-value')
      .text(setText)
      .each((d, index) => {
        this.__datapoint_info___ = {
          element: this,
          value: d,
          valueIndex: index,
          type: 'AXES',
          chart: cx.type,
        };
      });

    axis.selectAll('.domain').style('stroke', domainColor);

    this.drawAxisLabels();
  }

  /**
   * Draws the axis custom labels respective groups
   * @private
   */
  drawAxisLabels() {
    const { axisLabelOffset, axisLabel, isVertical, chartHeight, chartWidth } = this.config;
    if (axisLabel) {
      if (isVertical) {
        this.element
          .select('.y-axis-label')
          .append('text')
          .classed('y-axis-label-text', true)
          .attr('x', -chartHeight / 2)
          .attr('y', axisLabelOffset)
          .attr('text-anchor', 'middle')
          .attr('transform', 'rotate(270 0 0)')
          .text(axisLabel);
      } else {
        this.element
          .select('.x-axis-label')
          .append('text')
          .attr('y', axisLabelOffset)
          .attr('text-anchor', 'middle')
          .classed('x-axis-label-text', true)
          .attr('x', chartWidth / 2)
          .text(axisLabel);
      }
    }
  }

  setRange() {
    const { range, isVertical } = this.config;
    this.range = isVertical ? range.reverse() : range;
  }

  setDomain() {
    const { domain, isHorizontal } = this.config;
    this.domain = isHorizontal ? domain.reverse() : domain;
  }

  setScale() {
    this.scale = this.config.scale;
  }
}

/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-default-export */
// import * as d3 from 'd3';

import { roundedRect } from '../../utils';
import Tooltip from '../../helpers/tooltips';
import { bandCalcs } from './helpers/utils';
import * as LoadingMarkup from './helpers/load';
import { uniqueId } from './helpers/number';

/**
 * @typedef BarChartData
 * @type {Object[]}
 * @property {Number} value        Value of the group (required)
 * @property {String} name         Name of the group (required)
 *
 * @example
 * [
 *     {
 *         value: 1,
 *         name: 'glittering'
 *     },
 *     {
 *         value: 1,
 *         name: 'luminous'
 *     }
 * ]
 */

/**
 * @typedef LocaleObject
 * @type {Object}
 * @property {String} decimal       the decimal point(e.g., ".")
 * @property {String} thousands     the group separator(e.g., ",")
 * @property {Number[]} grouping    the array of group sizes(e.g., [3]), cycled as needed
 * @property {String[]} currency    the currency prefix and suffix(e.g., ["$", ""])
 * @property {String[]} numerals    optional; an array of ten strings to replace the numerals 0 - 9.
 * @property {String} percent       optional; the percent sign(defaults to "%")
 * @property {String} minus         optional; the minus sign(defaults to hyphen - minus, "-")
 * @property {String} nan           optional; the not - a - number value(defaults "NaN")
 *
 * See some standard locale object values [here]{@link https://cdn.jsdelivr.net/npm/d3-format/locale/}.
 * @example
 * {
 *     "decimal": ",",
 *     "thousands": ".",
 *     "grouping": [3],
 *     "currency": ["", "\u00a0€"]
 * }
 */

/**
 * Bar Chart reusable API class that renders a
 * simple and configurable bar chart.
 *
 * @module Bar
 * @tutorial bar
 * @requires d3-array, d3-axis, d3-dispatch, d3-scale, d3-selection
 *
 * @example
 * var barChart = bar();
 *
 * barChart
 *     .height(500)
 *     .width(800);
 *
 * d3.select('.css-selector')
 *     .datum(dataset)
 *     .call(barChart);
 *
 */
export default class SimpleBarChart {
  graphArea;

  _dimensions;

  _loadingState = LoadingMarkup.bar;

  GraphConfig;

  _data;

  _uniqueId = uniqueId('bar-chart');

  _xAxis;

  _yAxis;

  _svg;

  // extractors
  getRadiusByValue = (value, height) => {
    if (value === 0) return 0;
    return height < 12 ? height / 2 : 10;
  };

  constructor(element, config) {
    this.GraphConfig = config;
    this.graphArea = element;
    this.init();
  }

  /**
   * Draws the bars along the x axis
   * @param  {d3} bars Selection of bars
   * @return {void}
   */
  drawHorizontalBars(bars, graphCals) {
    // Enter + Update
    const cx = this;
    const { xScale, yScale } = this.GraphConfig;
    const bar = ({ x, y }) =>
      roundedRect(
        0,
        yScale(x),
        xScale(y),
        graphCals.bandwidth,
        cx.getRadiusByValue(y),
        false,
        true,
        false,
        true,
      );
    bars
      .enter()
      .append('path')
      .classed('bar', true)
      .attr('fill', d => d.color)
      .attr('transform', `translate(0, ${graphCals.translate})`)
      .attr('d', bar);
  }

  /**
   * Draws the bars along the y axis
   * @param  {d3} bars Selection of bars
   * @return {void}
   */
  drawVerticalBars(bars, graphCals) {
    // Enter + Update
    const cx = this;
    const { xScale, yScale, chartHeight, tooltipTemplate, type } = this.GraphConfig;
    bars
      .join('path')
      .classed('bar', true)
      .attr('fill', d => d.color)
      .attr('transform', `translate(${graphCals.translate}, 0)`)
      .attr('d', ({ x, y }) =>
        roundedRect(
          xScale(x),
          yScale(y),
          graphCals.bandwidth,
          chartHeight - yScale(y),
          cx.getRadiusByValue(y, chartHeight - yScale(y)),
          true,
          true,
        ),
      )
      .each(function (d, index) {
        this.__datapoint_info__ = {
          element: this,
          value: d,
          valueIndex: index,
          originalData: d.originalData,
          type: 'DATAPOIND',
        };
      })
      .on('mouseover', function (d, index, barList) {
        const tooltipContent = tooltipTemplate(this.__datapoint_info__, type);
        Tooltip.setContent(tooltipContent).show(d);
      })
      .on('mouseout', (event, d) => {
        Tooltip.hide();
      })
      .on('mousemove', function (d, index, barList) {
        const tooltipContent = tooltipTemplate(this.__datapoint_info__, type);
        Tooltip.setContent(tooltipContent).show(d);
      });
  }

  /**
   * Draws the bar elements within the chart group
   * @private
   */
  drawBars() {
    const { barWidth, yScale, xScale, isHorizontal } = this.GraphConfig;
    const graphCals = bandCalcs(barWidth, yScale, xScale, isHorizontal);
    const bars = this.graphArea.selectAll('.bar').data(this._data);

    if (isHorizontal) {
      this.drawHorizontalBars(bars, graphCals);
    } else {
      this.drawVerticalBars(bars, graphCals);
    }

    // Exit
    bars.exit().remove();
  }

  init() {
    this._chartWidth = this.GraphConfig.chartWidth;
    this._chartHeight = this.GraphConfig.chartHeight;
    this._data = this.GraphConfig.data;
    this.drawBars();
  }
}

function getTooltip(generateIfDoesntExist = true) {
    let tooltip = document.querySelector('.sf-chart-tooltip');

    if (!tooltip && generateIfDoesntExist) {
        tooltip = document.createElement('span');
        tooltip.classList.add('sf-chart-tooltip');
    }

    const parent = document.body;
    parent && tooltip && parent.appendChild(tooltip);

    return tooltip;
}

function setContent(html, theme) {
  let tooltip =  getTooltip()
  tooltip.innerHTML = html;
    if (theme) {
        tooltip.classList.add('sf-chart-tooltip--white');
    } else {
        tooltip.classList.remove('sf-chart-tooltip--white');
    }
    return this;
}

function show(mouseEvent) {
    var tooltip = getTooltip();
    var left = mouseEvent.pageX + 5;
    var windowWidth = window.innerWidth;

    if (left + 100 > windowWidth) {
        left = left - 110;
        tooltip.classList.add('tooltip-arrow-right');
        tooltip.classList.remove('tooltip-arrow-left');
    } else {
        tooltip.classList.add('tooltip-arrow-left');
        tooltip.classList.remove('tooltip-arrow-right');
    }

    tooltip.style.top = mouseEvent.pageY + 'px';
    tooltip.style.left = left + 'px';
    tooltip.style.display = 'initial';
    return this;
}

function hide() {
    getTooltip().style.display = 'none';
    return this;
}

export function remove() {
    const tooltip = getTooltip(false);
    tooltip && tooltip.remove();
}

/**** EXPORTS ****/
export default { setContent, show, hide, remove };

/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-default-export */
import * as d3 from 'd3';

import Tooltip from '../../helpers/tooltips';
import { bandCalcs } from './helpers/utils';
import * as LoadingMarkup from './helpers/load';
import { uniqueId } from './helpers/number';

/**
 * @typedef LineChartData
 * @type {Object[]}
 * @property {Number} value        Value of the group (required)
 * @property {String} name         Name of the group (required)
 *
 * @example
 * [
 *     {
 *         value: 1,
 *         name: 'glittering'
 *     },
 *     {
 *         value: 1,
 *         name: 'luminous'
 *     }
 * ]
 */

/**
 * @typedef LocaleObject
 * @type {Object}
 * @property {String} decimal       the decimal point(e.g., ".")
 * @property {String} thousands     the group separator(e.g., ",")
 * @property {Number[]} grouping    the array of group sizes(e.g., [3]), cycled as needed
 * @property {String[]} currency    the currency prefix and suffix(e.g., ["$", ""])
 * @property {String[]} numerals    optional; an array of ten strings to replace the numerals 0 - 9.
 * @property {String} percent       optional; the percent sign(defaults to "%")
 * @property {String} minus         optional; the minus sign(defaults to hyphen - minus, "-")
 * @property {String} nan           optional; the not - a - number value(defaults "NaN")
 *
 * See some standard locale object values [here]{@link https://cdn.jsdelivr.net/npm/d3-format/locale/}.
 * @example
 * {
 *     "decimal": ",",
 *     "thousands": ".",
 *     "grouping": [3],
 *     "currency": ["", "\u00a0€"]
 * }
 */

/**
 * Bar Chart reusable API class that renders a
 * simple and configurable bar chart.
 *
 * @module Bar
 * @tutorial bar
 * @requires d3-array, d3-axis, d3-dispatch, d3-scale, d3-selection
 *
 * @example
 * var barChart = bar();
 *
 * barChart
 *     .height(500)
 *     .width(800);
 *
 * d3.select('.css-selector')
 *     .datum(dataset)
 *     .call(barChart);
 *
 */
export default class LineChart {
  graphArea;

  _dimensions;

  _loadingState = LoadingMarkup.line;

  graphConfig;

  _data;

  _xScale;

  _yScale;

  _uniqueId = uniqueId('line-chart');

  _xAxis;

  _yAxis;

  _svg;

  chart;

  // extractors
  getRadiusByValue = (value, height) => {
    if (value === 0) return 0;
    return height < 12 ? height / 2 : 10;
  };

  constructor(graphArea, config) {
    this.graphConfig = config;
    this.graphArea = graphArea;
    this.init();
  }

  /**
   * Draws the bar graphAreas within the chart group
   * @private
   */
  drawLine() {
    const { barWidth, yScale, xScale, isHorizontal, data } = this.graphConfig;
    const graphCals = bandCalcs(barWidth, yScale, xScale, isHorizontal);

    this.chart = this.graphArea
      .append('g')
      .attr('type', 'LINE_CHART')
      .attr('class', 'sfviz-line chart');

    this.chart
      .append('path')
      .datum(data)
      .attr('class', 'sfviz-line-part spec-line-value')
      .attr('stroke', '#637EDD')
      .attr('stroke-width', 2)
      // .attr('stroke-dasharray', getLineDashArray(lineStyle.style, lineStyle.border))
      // .attr('stroke-linecap', lineStyle.style === 'DOTTED' ? 'round' : 'square')
      .attr('fill', 'none')
      .attr('transform', `translate(${[graphCals.bandSize / 2, 0]})`)
      .attr(
        'd',
        d3
          .line()
          .x(d => xScale(d.x))
          .y(d => yScale(d.y)),
      );
  }

  drawSymbol() {
    const { barWidth, yScale, xScale, isHorizontal, data, tooltipTemplate } = this.graphConfig;
    const graphCals = bandCalcs(barWidth, yScale, xScale, isHorizontal);
    this.chart
      .selectAll('dot')
      .data(data)
      .join('circle')
      .attr('class', 'sfviz-datapoint')
      .attr('r', 5)
      .attr('cx', d => xScale(d.x))
      .attr('fill', '#637EDD')
      .attr('stroke', '#CBD5FB')
      .attr('stroke-width', 1)
      .attr('cy', d => yScale(d.y))
      .attr('transform', `translate(${[graphCals.bandSize / 2, 0]})`)
      .each(function (d, index) {
        this.__datapoint_info__ = {
          element: this,
          value: d,
          originalData: d.originalData,
          valueIndex: index,
          type: 'DATAPOIND',
        };
      })
      .on('mouseover', (d, index, barList) => {
        const tooltipContent = tooltipTemplate(this.__datapoint_info__)
        Tooltip.setContent(tooltipContent).show(d);
      }).on('mouseout', (event, d) => {
        Tooltip.hide()
      })
      .on('mousemove', function (d, index, barList) {
        const tooltipContent = tooltipTemplate(this.__datapoint_info__)
        Tooltip.setContent(tooltipContent).show(d);
      })
  }

  init() {
    this.drawLine();
    this.drawSymbol();
  }
}

/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
import * as d3 from 'd3';

import { Fonts, Colors, NumberFormatDefault } from '../../constants';
import { formatNumber } from '../../utils';
import { singleColors } from './helpers/color';
import * as LoadingMarkup from './helpers/load';
import { uniqueId } from './helpers/number';
import { DataPreparation } from './helpers/DataPreparation';
import { Graph } from './Graph';
import Axis from './component/Axis';
import Legends from './component/Legends';
import { getTextWidth } from './helpers/text';

/**
 * Charts reusable API class that renders a
 * simple and configurable bar chart.
 *
 * @module Charts
 * @tutorial Charts
 * @requires d3-array, d3-axis, d3-dispatch, d3-scale, d3-selection
 *
 * @example
 * const comboChart = combo();
 *
 *
 */

// eslint-disable-next-line import/no-default-export
export default class Charts {
  config;

  element;

  _margin = {
    top: 20,
    right: 70,
    bottom: 30,
    left: 70,
  };

  _dimensions;

  _loadingState = LoadingMarkup.bar;

  _graphConfig;

  _data = [];

  layers = [];

  _scale = [];

  _uniqueId = uniqueId('bar-chart');

  _xAxis;

  _yAxis;

  _svg;

  _graphAreaBars;

  _graphAreaPlus;

  _minMax = { Left: null, Right: null };

  constructor(element, config, layers = []) {
    this.config = config;
    this.element = element;
    this.layers = [...layers];

    const dimensions = this.getDimensions();

    this._dimensions = {
      width: !config.options.width ? dimensions.width : config.options.width,
      height: !config.options.height ? dimensions.height : config.options.height,
    };
    this.setGraphConfig(config);
    this.init();
  }

  /**
   * Builds containers for the chart, the axis and a wrapper for all of them
   * Also applies the Margin convention
   * @private
   */
  buildContainerGroups() {
    const container = this._svg
      .append('g')
      .classed('container-group', true)
      .attr(
        'transform',
        `translate(${this._margin.left + this._graphConfig.yAxisPaddingBetweenChart}, ${
          this._margin.top + (this._graphConfig.hasMultiseries ? 50 : 0)
        })`,
      );

    this._graphAreaBars = container.append('g');

    this._graphAreaPlus = container.append('g');

    container.append('defs').classed('metadata-group', true);
    container
      .append('g')
      .classed('grid-lines-group', true)
      .attr(
        'style',
        `
                stroke: ${Colors.grayGridlines};
                `,
      );
    container.append('g').classed('chart-group', true);
  }

  /**
   * Builds the gradient element to be used later
   * @return {void}
   * @private
   */
  buildGradient() {
    if (this._graphConfig.chartGradientColors) {
      const colors = this._graphConfig.isHorizontal
        ? this._graphConfig.chartGradientColors.reverse()
        : this._graphConfig.chartGradientColors;
      const opacity = this._graphConfig.isHorizontal ? [0.4, 0.8] : [0.8, 0.4];

      this._svg
        .select('defs')
        .append('linearGradient')
        .attr('id', `chartGradient_${this._uniqueId}`)
        .attr('x1', '0%')
        .attr('y1', '0%')
        .attr('x2', this._graphConfig.isHorizontal ? '100%' : '0%')
        .attr('y2', this._graphConfig.isHorizontal ? '0%' : '100%')
        .attr('gradientUnits', 'userSpaceOnUse')
        .selectAll('stop')
        .data([
          { offset: '0%', color: colors[0], stopOpacity: opacity[0] },
          { offset: '100%', color: colors[1], stopOpacity: opacity[1] },
        ])
        .enter()
        .append('stop')
        .attr('offset', ({ offset }) => offset)
        .attr('stop-color', ({ color }) => color)
        .style('stop-opacity', ({ stopOpacity }) => stopOpacity);
    }
  }

  /**
   * Builds the SVG element that will contain the chart
   * @param  {HTMLElement} container DOM element that will work as the container of the graph
   * @private
   */
  buildSVG() {
    if (!this._svg) {
      this._svg = d3.select(this.element).append('svg').classed('sfviz-chart bar-chart', true);
      this.buildContainerGroups();
    }

    this._svg.attr('width', this._dimensions.width).attr('height', this._dimensions.height);
  }

  getDimensions() {
    const bound = this.element.getBoundingClientRect();
    return {
      height: Math.max(200, bound.height),
      width: Math.max(200, bound.width),
    };
  }

  resize(width, height) {
    const dimensions = this.getDimensions();

    this._dimensions = {
      width: !width ? dimensions.width : width,
      height: !height ? dimensions.height : height,
    };
    this.init();
  }

  redraw(config) {
    this.config = config;
    const dimensions = this.getDimensions();

    this._dimensions = {
      width: !config.options.width ? dimensions.width : config.options.width,
      height: !config.options.height ? dimensions.height : config.options.height,
    };

    this.setGraphConfig(config);

    this.element.innerHTML = '';
    this._svg = null;
    this.init();
  }

  setLayers() {
    const { colorSchema, layers, numberFormat } = this._graphConfig;
    const cx = this;
    if (this._graphConfig.layers.length === 0) {
      // eslint-disable-next-line no-console
      console.log('the list of layers that make up the graph is required.');
      return;
    }

    const defaultTemplate = (data, type) => {
      if (!data) return '';
      const yValue = formatNumber(data.value.y, numberFormat);

      if (type === 'BAR_CHART_MS') {
        return `<div class="tooltip"><h3 style="color:${data.value.color}">${data.value.serie}</h3><h4>${data.value.x}</h4><p><b>${yValue}<b></p></div>`;
      }

      return `<div class="tooltip"><h3>${data.value.x}</h3><p><b>${yValue}<b></p></div>`;
    };

    layers.forEach((layer, index) => {
      const layerConfig = {
        colorSchema,
      };
      const data = new DataPreparation({
        ...layerConfig,
        ...layer.settings,
        type: layer.type,
        data: layer.data,
        layerIndex: index,
        isCombo: this._graphConfig.isCombo,
      });

      const minMaxCalc = (position = 'Left', minMax) => {
        if (!cx._minMax[position]) cx._minMax[position] = [0, 0];
        if (minMax[0] < cx._minMax[position][0]) cx._minMax[position][0] = minMax[0];
        if (minMax[1] > cx._minMax[position][1]) cx._minMax[position][1] = minMax[1];
      };

      if (layer.type === 'BAR_CHART_MS') this._graphConfig.hasMultiseries = true;

      minMaxCalc(layer.axisPosition, [...data.minMax]);

      this.layers[index] = {
        index,
        type: layer.type,
        categories: data.categories,
        series: data.series,
        minMax: data.minMax,
        data: data.data,
        isCombo: this._graphConfig.isCombo,
        barWidth: this._graphConfig.barWidth,
        chartWidth: this._chartWidth,
        chartHeight: this._chartHeight,
        isHorizontal: this._graphConfig.isHorizontal,
        axisPosition: layer.axisPosition,
        axisNumberFormat: layer.axisNumberFormat || NumberFormatDefault,
        yLabel: layer.yLabel || `y - ${index}`,
        xLabel: layer.xLabel || `x - ${index}`,
        yTicks: layer.yTicks || 5,
        xTicks: layer.xTicks || 5,
        yTickSuffix: layer.yTickSuffix,
        xTickSuffix: layer.xTickSuffix,
        tooltipTemplate: layer.tooltipTemplate || defaultTemplate,
        enableLegend: this._graphConfig.enableLegend,
      };
    });
  }

  drawLayersGraphs() {
    const cx = this;
    // eslint-disable-next-line array-callback-return
    this.layers.map((layer, i) => {
      cx.drawGraphData(layer, i);
    });
  }

  drawGraphData(layer, i) {
    const cx = this;
    const area = ['BAR_CHART_MS', 'BAR_CHART'].includes(layer.type)
      ? this._graphAreaBars
      : this._graphAreaPlus;
    Graph.draw(area, { ...layer, chartWidth: cx._chartWidth, chartHeight: cx._chartHeight });
  }

  setGraphConfig(config) {
    this._graphConfig = {
      barWidth: config.options.barWidth || 50,
      chartWidth: null,
      chartHeight: null,
      colorSchema: config.options.colorSchema || singleColors.aloeGreen,
      chartGradientColors: config.options.chartGradientColors || null,
      yTicks: config.options.yTicks || 5,
      xTicks: config.options.xTicks || 5,
      numberFormat: config.options.numberFormat,
      enableLabels: config.options.enableLabels || false,
      enableMarkers: config.options.enableMarkers || false,
      labelsMargin: config.options.labelsMargin || 7,
      labelsSize: config.options.labelsSize || 15,
      betweenBarsPadding: config.options.betweenBarsPadding || 0.1,
      xAxisPadding: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
      yAxisPaddingBetweenChart: 0,
      yAxisLineWrapLimit: 1,
      isHorizontal: config.options.isHorizontal || false,
      orderingFunction: config.options.orderingFunction || null,
      valueLabel: config.options.valueLabel || 'value',
      nameLabel: config.options.nameLabel || 'name',
      xAxisLabel: config.options.xAxisLabel || null,
      xAxisLabelOffset: 30,
      yAxisLabel: config.options.yAxisLabel || null,
      yAxisLabelOffset: -30,
      grid: config.options.grid || 'full',
      tooltipTemplate: config.options.tooltipTemplate,
      percentageAxisToMaxRatio: 1,
      layers: config.options.layers || [],
      enableLegend: config.options.enableLegend || null,
      hasMultiseries: false,
      isCombo: config.options.layers.length > 1,
      axisFontSize: config.options.axisFontSize || 14,
    };
  }

  buildAxis() {
    const ctx = this;
    // eslint-disable-next-line array-callback-return
    this.layers.forEach((layer, i) => {
      const axisConfig = ctx.getAxisConfig(layer, i);
      this.drawAxis(axisConfig.ySettup);
      if (i === 0) this.drawAxis(axisConfig.xSettup);
    });
  }

  drawAxis(cfg) {
    const translate = this.calcTranslateAxis(cfg);
    const axisGroup = this._svg
      .append('g')
      .attr('class', `sfviz-axis sfviz-axis--${cfg.axis} ${cfg.axis} axis spec-text-labels`)
      .attr('transform', `translate(${translate})`);

    return new Axis(axisGroup, cfg);
  }

  buildLegend() {
    const { isCombo } = this._graphConfig;
    let data = [];

    if (isCombo) {
      this.layers.forEach(layer => {
        data.push(layer.yLabel);
      });
    } else {
      data = [...this.layers[0].series];
    }
    const cfg = {
      data,
      margin: this._margin,
      dimensions: this._dimensions,
      isCombo,
      chartWidth: this._chartWidth,
      chartHeight: this._chartHeight,
      isHorizontal: !this._graphConfig.isHorizontal,
      align: isCombo ? 'start' : 'center',
      colorSchema: this._graphConfig.colorSchema,
    };

    const legendGroup = this._svg
      .append('g')
      .classed('legend-container-group sfviz-legends', true)
      .attr('transform', `translate(${this._margin.left},${this._margin.top})`)
      .append('g')
      .classed('legend-group', true);

    return new Legends(legendGroup, cfg);
  }

  calcTranslateAxis(cfg) {
    const { axis, position } = cfg;
    const { yAxisPaddingBetweenChart, hasMultiseries } = this._graphConfig;
    const { left, top } = this._margin;
    if (axis === 'x') {
      const x = left + yAxisPaddingBetweenChart;
      const y = (hasMultiseries ? 50 : 0) + top + (position === 'Bottom' ? this._chartHeight : 0);
      return [x, y];
    }
    const x = left + yAxisPaddingBetweenChart + (position === 'Right' ? this._chartWidth : 0);
    const y = (hasMultiseries ? 50 : 0) + top;

    return [x, y];
  }

  getAxisConfig(layer, index) {
    const { isHorizontal, numberFormat } = this._graphConfig;
    const xSettup = {
      domain: isHorizontal ? layer.minMax : layer.categories,
      numberFormat: layer.axisNumberFormat,
      axisType: isHorizontal ? 'Continuous' : 'Categorical',
      axis: 'x',
      position: 'Bottom',
      data: layer.data,
      scale: isHorizontal ? layer.yScale : layer.xScale,
      range: [0, this._chartWidth],
      chartHeight: this._chartHeight,
      chartWidth: this._chartWidth,
      ticks: layer.xTicks,
      tickSuffix: layer.xTickSuffix,
    };
    const ySettup = {
      isVertical: true,
      domain: isHorizontal ? layer.categories : layer.minMax,
      numberFormat: layer.axisNumberFormat,
      axisType: isHorizontal ? 'Categorical' : 'Continuous',
      axis: 'y',
      position: layer.axisPosition || 'Left',
      data: layer.data,
      isCombo: layer.isCombo || false,
      scale: isHorizontal ? layer.xScale : layer.yScale,
      range: [0, this._chartHeight],
      chartHeight: this._chartHeight,
      chartWidth: this._chartWidth,
      ticks: layer.yTicks,
      tickSuffix: layer.yTickSuffix,
    };

    return { xSettup, ySettup };
  }

  setGraphDimension() {
    const { axisFontSize } = this._graphConfig;
    let axisLeftWidth = 0;
    let axisRightWidth = 0;

    if (this._minMax.Left) {
      axisLeftWidth = getTextWidth(this._minMax.Left[1], axisFontSize);
    }

    if (this._minMax.Right) {
      axisRightWidth = getTextWidth(this._minMax.Right[1], axisFontSize);
    }

    axisLeftWidth = Math.max(axisLeftWidth, this._margin.left);
    axisRightWidth = Math.max(axisRightWidth, this._margin.right);

    this._chartWidth =
      this._dimensions.width -
      axisLeftWidth -
      axisRightWidth -
      this._graphConfig.yAxisPaddingBetweenChart * 1.2;

    this._chartHeight =
      this._dimensions.height -
      this._margin.top -
      this._margin.bottom -
      (this._graphConfig.isCombo || this._graphConfig.hasMultiseries ? 50 : 0);
  }

  setLayerScales() {
    const { isHorizontal, betweenBarsPadding } = this._graphConfig;
    // const ctx = this;

    this.layers.forEach((layer, i) => {
      let xScale;
      let xScale2;
      let yScale;
      let yScale2;
      if (isHorizontal) {
        xScale = d3.scaleLinear().domain(layer.minMax).rangeRound([0, this._chartWidth]);

        yScale = d3
          .scaleBand()
          .domain(layer.domain)
          .rangeRound([this._chartHeight, 0])
          .padding(betweenBarsPadding);

        if (layer.series)
          yScale2 = d3
            .scaleBand()
            .domain(layer.series)
            .rangeRound([yScale.bandwidth(), 0])
            .padding(betweenBarsPadding);
      } else {
        xScale = d3
          .scaleBand()
          .domain(layer.categories)
          .rangeRound([0, this._chartWidth])
          .padding(betweenBarsPadding)
          .align(0.5);

        if (layer.series)
          xScale2 = d3
            .scaleBand()
            .domain(layer.series)
            .rangeRound([0, xScale.bandwidth()])
            .padding(betweenBarsPadding);
        yScale = d3.scaleLinear().domain(layer.minMax).rangeRound([this._chartHeight, 0]);
      }

      layer.yScale = yScale;
      layer.yScale2 = yScale2;
      layer.xScale = xScale;
      layer.xScale2 = xScale2;
    });
  }

  init() {
    this.setLayers();
    this.setGraphDimension();
    this.buildSVG();
    this.setLayerScales();
    this.buildAxis();
    this.buildGradient();
    this.drawLayersGraphs();

    if (
      this._graphConfig.enableLegend &&
      (this._graphConfig.isCombo || this._graphConfig.hasMultiseries)
    ) {
      this.buildLegend();
    }
  }
}

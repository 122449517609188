import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js/react-plotly';
import { Colors } from '../../constants';
// Component to render plots

const Graphs = ({
  layoutTitle,
  HandlerGraph,
  orientation,
  data,
  color,
  tickformat,
  fill,
  width,
  height,
}) => {
  const text = [data].map(vue => {
    if (vue.x) {
      return vue.x.map(
        (value, i) =>
          `<span style='color:${Colors.orange}; font-size:14px'>${
            orientation && tickformat === '$' ? formatCurrency(value) : value
          }</span><br> ${tickformat === '%' ? formatPercentage(vue.y[i] * 100) : vue.y[i]}${
            !orientation && tickformat ? tickformat : ''
          }<br> ${vue.cop[i]}`,
      );
    }
    return vue.y.map((value, i) => {
      return `<span style='color:${Colors.orange}; font-size:14px'> ${value}</span><br>${
        tickformat === '%' ? formatPercentage(vue.x[i] * 100) : vue.x[i]
      }${tickformat}<br> ${vue.cop[i]}`;
    });
  });
  let { y } = data;
  if (orientation && y[0].length > 8) {
    y = data.y.map(value => {
      return value.replace(/\s+/, '(...)<br />');
    });
  }
  const ConfigData = [
    {
      ...data,
      y,
      type: 'bar',
      hoverinfo: 'text',
      orientation,
      marker: {
        color,
      },
    },
    {
      ...data,
      y,
      type: 'scatter',
      fill: fill ? 'tozeroy' : '',
      fillcolor: Colors.primaTransparent,
      mode: 'lines+markers',
      text: text[0],
      hoverinfo: 'text',
      hoverlabel: { bgcolor: Colors.blue },
      marker: {
        color: Colors.secondary,
        size: 20,
        line: {
          width: 3,
          color: Colors.primary,
        },
      },
      line: {
        color: Colors.orange,
      },
    },
  ];

  const layout = {
    title: layoutTitle,
    showlegend: false,
    autosize: true,
    height: height || 'auto',
    width: width || 'auto',
    margin: 0,
    yaxis: {
      showgrid: orientation,
      ticks: 'outside',
      showline: !orientation,
      tickformat,
    },
    xaxis: {
      showgrid: !!orientation,
      showline: !!orientation,
    },
    bargap: !orientation ? 0.5 : 0.7,
    bargroupgap: 8,
  };

  const options = {
    scrollZoom: false,
    responsive: true,
    showLink: false,
    displayLogo: false,
    displayModeBar: false,
    useResizeHandler: true,
    style: {
      width: '100%',
      height: '100%',
    },
  };

  return <Plot data={ConfigData} layout={layout} onClick={HandlerGraph} config={options} />;
};
Graphs.defaultProps = {
  orientation: '',
  tickformat: '',
  color: Colors.primary,
  fill: false,
};

Graphs.propTypes = {
  config: PropTypes.object,
  layout: PropTypes.object,
  HandlerGraph: PropTypes.func,
  data: PropTypes.object.isRequired,
  layoutTitle: PropTypes.string.isRequired,
  orientation: PropTypes.string,
  color: PropTypes.string,
  tickformat: PropTypes.string,
  fill: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

export { Graphs };

// Percent formatter
const formatPercentage = num => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
};

const formatCurrency = value => {
  return `$${new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
    .format(value)
    .replace(/(?:$|\s.*)/, '')}`;
};

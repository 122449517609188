import * as d3 from 'd3';

/**
 * Validate if the recieved number is NaN.
 * This function gets any variable but it will return false.
 * Validate if variable is null, undefined, or an empty string, also, function uses isNaN native function.
 * @return True if variable is a NaN or false otherwise
 */

export const isNaNV2 = variable => {
  return variable == null || variable === '' || isNaN(variable);
};

export const isEmptyValue = variable => {
  return variable === undefined || variable === null || variable === '';
};

export function formatNumber(num, outputFormat) {
  try {
    num = +(num || 0);
  } catch (e) {
    num = 0;
  }
  if (isNaN(num)) num = 0;
  const numDecimals = !isEmptyValue(outputFormat.decimals) ? outputFormat.decimals : 2;

  switch (outputFormat.format) {
    case 'Abbreviated':
      return formatAbbreviated(num, numDecimals);
    case 'Numeric':
    case 'Decimal':
      return d3
        .format(`,.${numDecimals}f`)(num)
        .replace(/\.(%*)$/, '$1');
    case 'Currency': {
      const currency = `${num < 0 ? '-' : ''} ${outputFormat.currency.label || '$'}`;
      const absNum = Math.abs(num);
      return (
        currency +
        d3
          .format(`,.${numDecimals}f`)(absNum)
          .replace(/\.(%*)$/, '$1')
      );
    }
    case 'Percentage':
      return `${d3
        .format(`,.${numDecimals}f`)(num)
        .replace(/\.(%*)$/, '$1')}%`;
    case 'Scientific':
      return Number(num).toExponential(numDecimals).replace('.00', '').replace('e+0', '');
    case 'Abbreviated Currency':
      const currency = `${num < 0 ? '-' : ''} ${outputFormat.currency.label || '$'}`;
      const absNum = Math.abs(num);
      return currency + formatAbbreviated(absNum, numDecimals);
    default:
      return `${num}`;
  }
}

function formatAbbreviated(num, decimals) {
  if (num === 0) return 0;

  const absValue = Math.abs(num);
  const exp = Math.floor(Math.log10(absValue));

  if (exp < 0 && exp >= -3) return decimals ? num.toFixed(decimals) : num;

  return d3.format('.3s')(num);
}

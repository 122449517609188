/* eslint-disable class-methods-use-this */
/* eslint-disable no-self-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */

import { SEGMENT_KEY, SEGMENT_SOURCE, Environment } from '../constants';

class SfSegment {
  analytics;

  _SegmentReady = false;

  source = null;

  constructor() {
    if (SEGMENT_SOURCE) {
      this.source = SEGMENT_SOURCE;
      this.Environment = Environment.PRODUCTION;
    }
  }

  /**
   * Load
   * @param React-router history component
   * @param if true enable debug features on librarie (defaults to false)
   * @param enable events ( set current route on change )
   * @return none
   */
  load({ history, enableDebug = false, enableEvents = true }) {
    this.enableDebug = enableDebug;

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    // this.onChangeLocation();
    if (enableEvents) {
      this._initSegment();
      history.listen(location => {
        const pathname = location.pathname === '/' ? 'Home' : location.pathname;
        window.analytics.page(pathname);
      });
    }
  }

  _initSegment() {
    if (this.Environment === 'production' && !SEGMENT_KEY) {
      console.log('Missing SEGMENT_KEY env var');
    } else {
      // Create a queue, but don't obliterate an existing one!
      window.analytics = window.analytics || [];
      // If the real analytics.js is already on the page return.
      if (window.analytics.initialize) {
        return;
      }
      // If the snippet was invoked already show an error.
      if (window.analytics.invoked) {
        console.error('Segment snippet included twice.');
        return;
      }
      // Invoked flag, to make sure the snippet
      // is never invoked twice.
      window.analytics.invoked = true;
      // A list of the methods in Analytics.js to stub.
      window.analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ];
      // Define a factory to create stubs. These are placeholders
      // for methods in Analytics.js so that you never have to wait
      // for it to load to actually record data. The `method` is
      // stored as the first argument, so we can replay the data.
      const analyticsFactory = method => {
        return (...args) => {
          const Arguments = Array.prototype.slice.call(args);
          Arguments.unshift(method);
          window.analytics.push(Arguments);
          return window.analytics;
        };
      };
      // For each of our methods, generate a queueing stub.
      for (let i = 0; i < window.analytics.methods.length; i++) {
        const key = window.analytics.methods[i];
        window.analytics[key] = analyticsFactory(key);
      }
      // Define a method to load Analytics.js from our CDN,
      // and that will be sure to only ever load it once.
      window.analytics.load = (key, options) => {
        // Create an async script element based on your key.
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
        // Insert our script next to the first script element.
        const first = document.getElementsByTagName('script')[0];
        first.parentNode.insertBefore(script, first);
        window.analytics._loadOptions = options;
      };

      window.analytics._writeKey = SEGMENT_KEY;
      // Add a version to keep track of what's in the wild.
      window.analytics.SNIPPET_VERSION = '4.13.2';
      // Load Analytics.js with your key, which will automatically
      // load the tools you've enabled for your account. Boosh!
      window.analytics.load(SEGMENT_KEY);

      window.analytics.page();

      // Turns debug mode
      window.analytics.debug(this.enableDebug);

      // eslint-disable-next-line no-console
      console.debug('Segment successfully initiated');

      window.analytics = window.analytics;
      this._SegmentReady = true;
    }
  }

  /*
    Reset or logout
  */
  Reset() {
    window.analytics.reset();
  }

  /**
   * TrackLink
   * @param Name of the event to be tracked
   * @param opts: (optional) key/value pairs as properties on the event
   * @return none
   */
  TrackEvent(eventName, opts = {}) {
    window.analytics.track(eventName, { ...opts, source: this.source });
  }

  /**
   * TrackLink
   * @param DOM element to bind with track method
   * @param The name of the event, passed to the track method.
   * @return none
   *
   * example for banner promotion

    document.querySelector(".banner").addEventListener(
      "click",
      (e) => {
        Segment.TrackLink(e.target, "BANNER_HOME_CLICK", {
          type: 'PROMOTION',
          title: e.target.title
        });
        // Log the clicked element in the console
        console.log(e.target);
      },
      false
    );

    *
   */
  TrackLink(element, eventName, opts = {}) {
    window.analytics.trackLink(element, eventName, { ...opts, source: this.source });
  }

  /**
   * Identify
   * The identify method is how you link your users, and their actions.
   * @param The database ID for the user.
   * @param A dictionary of traits you know about the user, like their email or name. : { email, name, phone }
   * @return none
   */
  Identify(userId, detail) {
    window.analytics.identify(userId, detail);
  }

  /**
   * Page
   * The Page method lets you record page views on your website.
   * @param The category of the page. Useful for cases like ecommerce where many pages might live under a single category.
   * @param The name of the page.
   * @param A dictionary of properties of the page. Note: Analytics.js collects url, title, referrer and path are automatically.
   * @return none
   */
  Page(category, name, opts = {}) {
    if (!this._SegmentReady) return;
    window.analytics.page(category, name, { ...opts, source: this.source });
  }

  // onChangeLocation() {
  //   if (!this._SegmentReady) return;
  //   // eslint-disable-next-line no-restricted-globals
  //   const { pushState } = history;
  //   const cx = this;
  //   // eslint-disable-next-line no-restricted-globals
  //   history.pushState = (...args) => {
  //     // eslint-disable-next-line no-restricted-globals
  //     pushState.apply(history, args);
  //     cx.analytics.page(null, args);
  //   };
  // }
}

const Segment = new SfSegment();

export { Segment };

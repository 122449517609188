import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { loadAllClients } from '@superfuds/sf-feature-gating';
import { i18n } from '../sf-i18n';
import { Environment } from '../constants';
import { useMount } from '../hooks';
import { eventsClient } from '../sf-events';
import { Segment } from '../sf-segment';

export const SfApp = ({
  children,
  environment,
  user,
  history,
  loadingComponent,
  enableDebug,
  enableEvents,
}) => {
  const [clientsLoaded, setClientsLoaded] = useState(false);

  useEffect(() => {
    Promise.all([
      loadAllClients({
        splitOpts: {
          environment: environment === Environment.LOCAL ? Environment.STAGING : environment,
          user,
        },
      }),
      i18n.init(),
    ]).then(() => setClientsLoaded(true));
    Segment.Page();
  }, [environment, user]);

  useMount(() => {
    eventsClient.load({
      history,
      environment,
      enableDebug,
      enableEvents,
    });

    Segment.load({
      history,
      enableDebug,
      environment,
      enableEvents,
    });
  });

  if (!clientsLoaded) {
    return loadingComponent;
  }

  return (
    <div className="sf-app">
      <main className="sf-app__content">{children}</main>
    </div>
  );
};

SfApp.defaultProps = {
  loadingComponent: <p>Loading...</p>,
  enableDebug: false,
  enableEvents: true,
};

SfApp.propTypes = {
  environment: PropTypes.oneOf([Environment.LOCAL, Environment.STAGING, Environment.PRODUCTION])
    .isRequired,
  user: PropTypes.shape({
    eid: PropTypes.string.isRequired,
  }).isRequired,
  // react-router history component
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
  }).isRequired,
  // Custom component that will appear meanwhile is initializing all the clients
  loadingComponent: PropTypes.node,
  // (optional) if true enable debug features on libraries (defaults to false)
  enableDebug: PropTypes.bool,
  // (optional) if true enable clevertap, GE and pixel libraries (defaults to true)
  enableEvents: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
